.component-card {
	position: relative;
	z-index: 2;

	.card {
		border-radius: 2px;
		box-shadow: 0 0 10px @color-dark;
		margin: 10em auto 0;
		padding: 0;
		position: relative;
		width: 460px;
	}

	header {
		._clearfix();
		background-color: @color-dark;
		border-radius: @border-radius-content @border-radius-content 0 0;
		color: @color-text-inverted;
		padding-top: 1em;

		a {
			color: @color-link-inverted;
		}
		a:hover, a:focus {
			color: @color-link-active-inverted;
		}
	}

	.title,
	.subtitle {
		float: left;
		font-weight: normal;
		margin: 0;
		width: 50%;
	}

	.title {
		._rem-px(font-size, @font-size-l);
		font-family: "zerothrees", "Helvetica Neue", Helvetica, Arial, sans-serif;
		line-height: 2em;
		padding: .6em 1em;
		white-space: nowrap;

		a {
			text-decoration: none;
		}

		img {
			max-height: 40px;
		}

		span,
		em {
			._rem-px(font-size, @font-size-xxl);
			vertical-align: middle;
		}

		em {
			font-style: normal;
		}

		strong {
			._rem-px(font-size, @font-size-xl);
			font-weight: normal;
			padding: 0 .25em;
		}
	}

	.subtitle {
		._rem-px(font-size, @font-size-m);
		line-height: 1.3em;
		padding: .8em 1em 1em 5%;
	}

	.content {
		._rem-px(font-size, @font-size-s);
		background-color: @color-lighter;
		padding: 3em 1em 1em;
		text-align: center;

		.name {
			._rem-px(font-size, @font-size-l);
			font-family: "zerothrees", "Helvetica Neue", Helvetica, Arial, sans-serif;
			font-weight: normal;
			line-height: 1em;
			margin: 0;
		}
	}

	footer {
		._rem-px(font-size, @font-size-s);
		background-color: @color-lighter;
		border-radius: 0 0 @border-radius-content @border-radius-content;

		ul {
			display: block;
			list-style-type: none;
			margin: 0;
			padding: 0;
			text-align: center;
		}

		li,
		a {
			display: inline-block;
		}

		a {
			line-height: 1em;
			padding: 1em;
		}
	}

	@media (max-width: @grid-small) {
		.card {
			margin-top: 0;
			width: auto;
		}

		header .title,
		header .subtitle {
			float: none;
			text-align: center;
			width: auto;
		}

		header .title {
			padding: 0;
		}

		header .subtitle {
			padding-top: 0;
		}

		.content {
			padding: 2em 1em;
		}
	}
}
