* {
	box-sizing: border-box;
}

body {
	._rem-px(font-size, @font-size-m);
	background: @color-light;
	color: @color-dark;
	font-family: "asapregular", "Helvetica Neue", Helvetica, Arial, sans-serif;
	letter-spacing: .05em;
	line-height: 1.5;
	padding: 0;
}

a {
	color: @color-link;
	text-decoration: underline;
}

a:hover,
a:focus {
	color: @color-link-active;
}

p {
    margin-top: 0.75em;
    margin-bottom: 0;
}

@media (max-width: @grid-small) {
	body {
		min-width: @grid-minimum;
		padding: @grid-space-unit @grid-space-unit 0;
	}
}
