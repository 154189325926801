.component-timeline {
	._clearfix();
	position: relative;
	z-index: 1;

	&:before {
		background-color: @color-lighter;
		box-shadow: 0px 0px 2px #aaa inset;
		bottom: 0;
		content: '';
		display: block;
		font-size: inherit;
		left: 0;
		position: absolute;
		top: 0;
		width: .5em;
	}

	article {
		._rem-px(font-size, @font-size-s);
		background-color: @color-lighter;
		border-radius: @border-radius-content;
		box-shadow: 0 0 5px @color-dark;
		margin-bottom: 1em;
		position: relative;

		&:before {
			border-color: transparent @color-lighter transparent transparent;
			border-style: solid;
			border-width: .5em;
			content: '';
			height: 0;
			position: absolute;
			right: 100%;
			top: 1.5em;
			width: 0;
		}

		// &:after {
		// 	background-color: @color-dark;
		// 	border-radius: 50%;
		// 	content: '';
		// 	display: block;
		// 	font-size: inherit;
		// 	height: .5em;
		// 	left: -2em;
		// 	position: absolute;
		// 	top: 1.75em;
		// 	width: .5em;
		// }

		header,
		footer {
			padding: .5em 1em;
		}

		.content {
			padding: 0 1em;
		}

		footer {
			background-color: @color-dark;
			border-radius: 0 0 @border-radius-content @border-radius-content;
			color: @color-light;
		}

		.title {
			._rem-px(font-size, @font-size-l);
			margin: 0;

			a {
				text-decoration: none;
			}

			.recruiter {
				._rem-px(font-size, @font-size-s);
				white-space: nowrap;
			}
		}

		.description {
			margin: .25em 0;
		}

		.keywords {
			list-style-type: none;
			margin: 0;
			padding: 0;

			li {
				// background-color: @color-dark;
				// border-radius: @border-radius-content;
				// color: @color-light;
				display: inline-block;
				// margin-bottom: 2px;
				// padding: 0 2px;
			}
		}
	}

	@media (min-width: @grid-small) {
		margin: 0 auto;
		max-width: @grid-medium;
		padding-bottom: 5em;
		padding-top: 10em;

		&:before {
			left: 50%;
			margin-left: -.25em;
		}

		article {
			clear: both;
			width: ~"calc(50% - 2.75em)";

			&:nth-child(2n) {
				right: 1em;
				float: right;
			}

			&:nth-child(2n+1) {
				float: left;
				left: 1em;

				&:before {
					border-color: transparent transparent transparent @color-lighter;
					left: 100%;
					right: auto;
				}

				// &:after {
				// 	left: auto;
				// 	right: -2em;
				// }
			}
		}
	}

	@media (max-width: @grid-small) {
		padding: 3em 2em 3em 0;

		article {
			left: 2em;
		}
	}
}

.has-js .component-timeline article {
	opacity: 0;

	&.visible {
		opacity: 1;
	}
}

.has-js .component-timeline.animated article {
	transition: opacity 1s;
}
