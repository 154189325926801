@font-face {
    font-family: 'asapregular';
    src: url('../fonts/asapregular/asap-regular-webfont.woff') format('woff');
    src: url('../fonts/asapregular/asap-regular-webfont.eot');
    src: url('../fonts/asapregular/asap-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/asapregular/asap-regular-webfont.ttf') format('truetype'),
         url('../fonts/asapregular/asap-regular-webfont.svg#asapregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'zerothrees';
    src: url('../fonts/zerothrees/zero-threes-webfont.woff') format('woff');
    src: url('../fonts/zerothrees/zero-threes-webfont.eot');
    src: url('../fonts/zerothrees/zero-threes-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/zerothrees/zero-threes-webfont.ttf') format('truetype'),
         url('../fonts/zerothrees/zero-threes-webfont.svg#zerothrees') format('svg');
    font-weight: normal;
    font-style: normal;
}
