// REM/PX PROPERTIES
._rem-px (@property, @value) {
	@remValue: @value;
	@pxValue: (@value * 16); // 16px based

	@{property}: ~"@{pxValue}px";
	@{property}: ~"@{remValue}rem";
}

// NO TAP
._no-tap () {
	outline: 0;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent; /* For some Androids */
}

// NO TYPO
._no-typo () {
	font-size: 0;
	line-height: 0;
}

// CLEARFIX
._clearfix () {
	&:before,
	&:after {
		font-size: 0;
		letter-spacing: 0;
		content: "";
		display: table;
	}
	&:after {
		clear: both;
	}
}
